<template>
  <div class="login">
    <div class="main">
      <swiper class="wrapper"
              :options="swiperOption">
        <swiper-slide class="swiper-item">
          <img class="swiper-img"
               src="../assets/img/login-banner4.png"
               alt>
        </swiper-slide>
        <swiper-slide class="swiper-item">
          <img class="swiper-img"
               src="../assets/img/login-banner5.png"
               alt>
        </swiper-slide>
        <swiper-slide class="swiper-item">
          <img class="swiper-img"
               src="../assets/img/login-banner1.png"
               alt>
        </swiper-slide>
        <swiper-slide class="swiper-item">
          <img class="swiper-img"
               src="../assets/img/login-banner2.png"
               alt>
        </swiper-slide>
        <swiper-slide class="swiper-item">
          <img class="swiper-img"
               src="../assets/img/login-banner3.png"
               alt>
        </swiper-slide>
      </swiper>
      <div class="centent">
        <img src="../assets/img/QianXun/QX.png"
             alt="">
        <h1>联合飞行系统</h1>
        <Row type="flex"
             justify="center"
             class="lg">
          <Col span="16"
               class="lg">
          <Form ref="formInline"
                :model="formInline"
                :rules="ruleInline"
                id="loginForm">
            <FormItem prop="user">
              <Row type="flex"
                   justify="center"
                   class="lg">
                <Col span="4"
                     class="lg">
                <div class="icomBox">
                  <img src="../assets/img/Icon/id.png"
                       alt="">
                </div>
                </Col>
                <Col span="20"
                     class="lg">
                <Input type="text"
                       v-model="formInline.user"></Input>
                </Col>
              </Row>
            </FormItem>

            <FormItem prop="password">
              <Row type="flex"
                   justify="center"
                   class="lg">
                <Col span="4"
                     class="lg">
                <div class="icomBox">
                  <img src="../assets/img/Icon/password.png"
                       alt="">
                </div>
                </Col>
                <Col span="20"
                     class="lg">
                <Input type="password"
                       v-model="formInline.password"
                       @on-enter="login()"></Input>
                </Col>
              </Row>
            </FormItem>
            <FormItem>
              <CheckboxGroup v-model="formInline.checkbox">
                <Checkbox label="记住密码"></Checkbox>
              </CheckboxGroup>
            </FormItem>
            <FormItem>
              <Row type="flex"
                   justify="center"
                   class="lg">
                <input type="button"
                       value="登录"
                       class="logIn"
                       @click="login()">
              </Row>
            </FormItem>
          </Form>
          </Col>
        </Row>
        <!--<div class="Powered">-->
        <!--<h3 style="">Powered by</h3>-->
        <!--<img src="../assets/img/dji@2x.png" alt="">-->
        <!--</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/font/font.css';
export default {
  name: 'login',
  data: function () {
    return {
      swiperOption: {
        //循环
        loop: true,
        //设定初始化时slide的索引
        initialSlide: 0,
        //自动播放
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        //滑动速度
        speed: 1500,
        //滑动方向
        direction: 'horizontal',
        //小手掌抓取滑动
        grabCursor: true,
      },
      // 表单
      formInline: {
        user: '',
        password: '',
        checkbox: ['记住密码'],
      },
      ruleInline: {
        user: [
          { required: true, message: '请填写用户名', trigger: 'blur' },
          {
            type: 'string',
            min: 6,
            max: 11,
            message: '用户名长度 6 - 11个字符',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '用户名长度 6 - 12个字符' },
        ],
      },
    };
  },
  mounted() {
    // 记住密码
    if (typeof sessionStorage.account != 'undefined') {
      this.formInline.user = sessionStorage.account;
      this.formInline.password = sessionStorage.pwd;
    }
  },
  methods: {
    login() {
      sessionStorage.clear();
      var _this = this;
      this.$refs['formInline'].validate((valid) => {
        if (valid) {
          _this
            .$post('/admin/login/Login', {
              account: _this.formInline.user,
              password: _this.formInline.password,
              // password: _this.formInline.password,
              client: 'web',
            })
            .then((res) => {
              if (res.code == 0) {
                _this.$Notice.error({
                  title: '登录提示',
                  desc: res.msg_customer,
                });
              } else {
                // 储存数据
                sessionStorage.setItem(
                  'user_info',
                  JSON.stringify(res.data.user_info)
                );
                let token = '?token=' + res.data.token;
                sessionStorage.setItem('token', token);
                // let type = res.data.user_info.type;
                // sessionStorage.setItem("userType", type);
                if (res.data.user_info.is_manager === 0) {
                  sessionStorage.setItem('userType', 2);
                } else {
                  sessionStorage.setItem('userType', 1);
                }
                // 跳转页面
                if (typeof res.data.user_info.name != 'undefined') {
                  _this.$router.push({
                    path: '/live/watch-lists',
                  });
                }
                // 记住密码
                if (_this.formInline.checkbox.length > 0) {
                  sessionStorage.account = _this.formInline.user;
                  sessionStorage.pwd = _this.formInline.password;
                }
              }
            });
        } else {
          this.$Message.error('请正确填写用户名和密码');
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  @include wh(1200, 748);
  @include center;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  .wrapper {
    @include wh(720, 748);
    margin: 0;
    .swiper-item {
      @include wh(720, 748);
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  .centent {
    padding-top: pcRem(150);
    width: pcRem(480);
    position: relative;
    > img {
      width: pcRem(200);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: pcRem(15);
    }
    h1 {
      @include sc(28, #6a6a6a);
      text-align: center;
      font-weight: bold;
      margin-bottom: pcRem(15);
      font-family: 'SY';
    }
    h2 {
      @include sc(24, #757172);
      text-align: center;
      font-weight: bold;
      margin-bottom: pcRem(56);
    }
    #loginForm {
      .icomBox {
        width: 100%;
        height: 100%;
        border-bottom: solid 1px #707070;
        img {
          @include center;
          width: pcRem(16);
          height: auto;
        }
      }
      .logIn {
        @include wh(274, 36);
        line-height: pcRem(36);
        background-color: #333333;
        @include sc(18, #fff);
        letter-spacing: pcRem(4);
        cursor: pointer;
        border: none;
      }
    }
    .Powered {
      width: 100%;
      position: absolute;
      bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      h3 {
        @include sc(16);
        margin-right: 6px;
      }
      img {
        width: pcRem(35);
        height: auto;
      }
    }
  }
}
</style>
